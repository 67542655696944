import React, { useRef, createRef, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Icon,
    Text,
    Divider,
    Textarea,
    ButtonGroup,
    Box,
    FormLabel,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,

    useDisclosure
} from "@chakra-ui/react";

import { MdFeedback, MdQuestionAnswer, MdCheckCircle, MdUndo, MdSave, MdCancel } from "react-icons/md";

import { AddIcon, MinusIcon, InfoOutlineIcon } from "@chakra-ui/icons";

const FeedbackBox = (props) => {

    const responseRef = createRef();

    const [feedback, setFeedback] = useState(props.feedback)
    const [complete, setComplete] = useState(props.complete ? true : false);
    const [response, setResponse] = useState(props.response ? props.response : false);
    const [actioned, setActioned] = useState(props.actioned);
    const [hasSaved, setHasSaved] = useState(true);


    const saveThisOne = (key, val) => {
        const temp = {
            feedback,
            complete,
            response,
            actioned
        };

        temp[key] = val;
        props.save(props.id, temp);
    }


    const setFeedbackSave = n => {
        setFeedback(n);
        saveThisOne("feedback", n);
    }

    const setCompleteSave = n => {
        setComplete(n);
        saveThisOne("complete", n);
    }

    const setResponseSave = n => {
        setResponse(n);
        setHasSaved(true);
        saveThisOne("response", n);
    }

    const setActionedSave = n => {
        setActioned(n);
        saveThisOne("actioned", n);
    }

    if(props.isAccordion) {
        return (
            <AccordionItem borderLeftWidth={1} borderRightWidth={1}>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                        {complete && <Icon as={MdCheckCircle} mr={3} color="green.500"/>}
                        <Text fontWeight="bold" display="inline">
                            {!complete && feedback}
                            {complete && <s>{feedback}</s>}
                            {response && <Text as="span" fontWeight="normal" fontSize="sm"> - {response}</Text>}
                        </Text>
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} position="relative">
                    
                    <FormLabel fontSize="md" fontWeight="bold">
                        <Textarea
                            ref={responseRef}
                            defaultValue={response ? response : ""}
                            isDisabled={complete}
                            variant="filled"
                            placeholder="Type response here..."
                            onInput={e => {
                                setResponse(e.target.value);
                            }}
                            onBlur={e => {
                                setResponseSave(e.target.value);
                                setHasSaved(false);
                            }}
                            mb={2}
                            mt={2}
                            size={"sm"}
                            rows={2}
                        />
                    </FormLabel>
                    <ButtonGroup isAttached={true} variant="solid" size="sm" w="100%">
                        {!complete && <Button w="100%" rightIcon={<MdCheckCircle />} colorScheme="green" onClick={() => setCompleteSave(true)}>Mark Complete</Button>}
                        {complete && <Button w="100%" rightIcon={<MdUndo />} colorScheme="red" variant="outline" onClick={() => setCompleteSave(false)}>Unmark Complete</Button>}
                    </ButtonGroup>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
    } else {
        return (
            <Box w="100%">
                <Textarea
                    defaultValue={feedback ? feedback : ""}
                    placeholder="Type question or feedback here..."
                    onInput={e => {
                        setFeedback(e.target.value);
                    }}
                    onBlur={e => {
                        setFeedbackSave(e.target.value);
                        setHasSaved(false);
                    }}
                    mb={props.isLast ? 0 : 2}
                    mt={2}
                    size={"md"}
                    rows={4}
                />
            </Box>
        );
    }

}

export default FeedbackBox;