import gql from 'graphql-tag';


export const RUN_HEARTBEAT = gql `
query Heartbeat($id: ID!) {
	user(id: $id, idType: DATABASE_ID) {
		id
		email
		avatar {
			url
		}
		firstName
		lastName
		roles {
		  nodes {
			name
			displayName
			capabilities
		  }
		}
		username
		jwtAuthToken
	}
}
`;

export const ALL_SECTIONS = gql `
query AllSections {
	sections (first: 100) {
		nodes {
			slug
			title
			terms {
				nodes {
				  name
				  slug
				}
			}
			sectionData {
				customPostType {
					postTypeName
					timeToSetup
				}
				webExamples {
					url
				}
				requiredPages {
					pageName
					pageTemplate {
						... on WireframeTemplate {
							id
						}
					}
				}
				limitations {
					name
				}
					hasRequiredPages
					hasCustomPostType
					developmentTime
					designTime
					description
				features {
					name
				}
				image {
					sourceUrl
				}
			}
		}
	}
}
`;

export const SECTION_CONTENT_TYPES = gql `
query SectionContentTypes {
	contentTypes {
	  nodes {
		name
		sections {
		  nodes {
			slug
		  }
		}
	  }
	}
} 
`;

export const SECTION_PAGE_TYPES = gql `
query SectionPageTypes {
	pageTypes {
	  nodes {
		name
		sections {
		  nodes {
			slug
		  }
		}
	  }
	}
} 
`;


const template_frag = `
title
id
wireframeTemplateData {
  templateStructure {
	section {
	  ... on Section {
		id
		slug
	  }
	}
  }
}
`;

export const ALL_TEMPLATES = gql `
query AllTemplates {
	wireframeTemplates {
		nodes {
			${template_frag}
		}
	}
}  
`;

export const TEMPLATE_BY_ID = gql `
query TemplateById ($id: ID!) {
	wireframeTemplate(id: $id, idType: ID) {
		${template_frag}
	}
  }
`;


const strategies_frag = `
title
id
databaseId
strategyData {
	strategyStage
	progress
}
author {
	node {
		id
		email
		avatar {
			url
		}
		firstName
		lastName
		roles {
		  nodes {
			name
			displayName
			capabilities
		  }
		}
		username
	}
}
`;

export const USER_STRATEGIES = gql `
query UserStrategies ($authorId: Int!) {
	strategies(where: {author: $authorId}) {
		nodes {
			${strategies_frag}
		}
	}
}
`;

export const ALL_STRATEGIES = gql `
query AllStrategies {
	strategies (first: 1000) {
		nodes {
			${strategies_frag}
		}
	}
}
`;

const strategy_frag = `
strategyData {
	sessionData
	strategyStage
	historyLog
	progress
	feedback {
		id
        actioned
        complete
        feedback
        response
    }
	client {
		... on Client {
			id
			client {
			accountsContact {
				email
				firstName
				lastName
				phone
			}
			businessInformation {
				alternateBillingBusinessName
				businessAbn
				businessAddress {
				addressLine1
				addressLine2
				postcode
				state
				suburb
				}
				businessName
				websiteUrl
			}
			primaryContact {
				email
				firstName
				lastName
				phone
			}
			}
			title
		}
	}
  }
  title
  id
  author {
	node {
	  username
	  firstName
	}
}
`;

export const STRATEGY_BY_ID = gql `
query StrategyById ($id: ID!){
	strategyBy(id: $id) {
		${strategy_frag}
	}
}
`;

export const STRATEGY_BY_DBID = gql `
query StrategyByDBId ($id: Int!){
	strategyBy(strategyId: $id) {
		${strategy_frag}
	}
}
`;


//note that authorId is the base64 encoded version.
// export const CREATE_STRATEGY = gql`
// mutation CreateStrategy ($authorId: String!, title: String!, slug: String!) {
// 	__typename
// 	createStrategy(input: {authorId: $authorId, title: $title, slug: $slug, status: PUBLISH}) {
// 		strategy {
// 			id
// 		}
// 	}
//   }  
// `

export const CREATE_STRATEGY = gql `
mutation CreateStrategy ($title: String!) {
	createStrategy(input: {title: $title, status: PUBLISH}) {
		strategy {
			id,
			title
		}
	}
  }  
`;

export const SAVE_STRATEGY = gql `
mutation SaveStrategy ($id: Int!, $title: String, $sessionData: String, $progress: Int, $strategyStage: StrategyStage, $feedback: String) {
	updateStrategySession(input: {
		id: $id,
		title: $title,
		sessionData: $sessionData,
		progress: $progress,
		strategyStage: $strategyStage,
		feedback: $feedback
	}) {
	  sessionData
	}
}
`

export const SAVE_STRATEGY_FEEDBACK = gql `
mutation SaveStrategyFeedback ($id: Int!, $feedback: String) {
	updateStrategySession(input: {
		id: $id,
		feedback: $feedback
	}) {
	  sessionData
	}
}
`

export const SAVE_STRATEGY_STAGE = gql `
mutation SaveStrategyStage ($id: Int!, $strategyStage: StrategyStage!) {
	updateStrategySession(input: {
		id: $id,
		strategyStage: $strategyStage
	}) {
	  sessionData
	}
}
`;

export const LOGIN_USER = gql `
mutation LoginUser ($username: String!, $password: String!) {
	login( input: {
		username: $username,
		password: $password
	} ) {
		authToken
		user {
			id
			name
		}
	}
}
`;

export const REGISTER_USER = gql `
mutation RegisterUser ($firstName: String!, $lastName: String!, $username: String!, $password: String!, $email: String!) {
	registerUser(
	  input: {
		  username: $username,
		  password: $password,
		  email: $email,
		  firstName: $firstName,
		  lastName: $lastName
	  }) {
	  user {
		jwtAuthToken
		jwtRefreshToken
	  }
	}
  }
`;