import React from 'react';
import { Center, Image, CircularProgress } from "@chakra-ui/react";

const FullscreenLoader = (props) => {
    return (
        <Center h="100vh">
            {props.text ? <>
            {props.text} &nbsp;&nbsp;
            <CircularProgress isIndeterminate color="purple.300" />
            </> : <>
            <Image boxSize="120px" alt="Kin Loading Animation" src="https://strategy.embarkagency.com.au/wp/wp-content/uploads/2021/08/kin-loading-loop.gif" ignoreFallback={true}></Image>
            </>}
        </Center>
    );
}

export default FullscreenLoader;